import type {Club, User} from "@spoferan/spoferan-ts-core";
import {getCountryEmoji, getSportTypeEmoji} from "../helpers/emoji_helpers";
import {MainModelPreview} from "@spoferan/spoferan-ts-core";

export function useGoogleSchema() {
    const {$image, $web} = useNuxtApp();
    const route = useRoute();
    const config = useRuntimeConfig();

    function getEventSchemaId() {
        return `${config.public.appUrl}${route.path}/#event`;
    }

    function getClubOrganizationSchema(club: Club) {
        return {
            '@type': 'SportsOrganization',
            'name': club.display_name,
            'alternateName': club.name_short ? club.name_short : undefined,
            'description': getClubSeoDescription(club),
            'url': $web('clubs.show.index', club._key, true),
            'sport': club.sportTypes && club.sportTypes.length ? club.sportTypes[0].title : undefined,
            'logo': club.avatar_thumbnail ? {
                '@type': 'ImageObject',
                'contentUrl': $image.format(club.avatar_thumbnail, {width: 85, height: 85 * club.avatar_thumbnail.ratio!}),
                'width': 85,
                'height': 85 * club.avatar_thumbnail.ratio!
            } : undefined,
            'address': club.country_code ? {
                '@type': 'PostalAddress',
                'addressCountry': club.country_code,
                'addressLocality': club.city ? club.city : undefined,
                'postalCode': club.postcode ? club.postcode : undefined,
                'streetAddress': club.street ? club.street : undefined,
            } : undefined,
            'location': club.country_code ? {
                '@type': 'PostalAddress',
                'addressCountry': club.country_code,
                'addressLocality': club.city ? club.city : undefined,
                'postalCode': club.postcode ? club.postcode : undefined,
                'streetAddress': club.street ? club.street : undefined,
            } : undefined,
        }
    }

    function getClubSeoDescription(club: Club) {
        const {$i18n} = useNuxtApp();

        let textParts = [];

        let clubText = club.presentation_type_name;
        if (club.country_code) {
            const emoji = getCountryEmoji(club.country_code);
            clubText += ' ' + $i18n.t('conjunction.from') + ' ' + (club.coarse_address ?? `${club.city}, ${club.country_code.toUpperCase()}`) + (emoji !== '' ? (' ' + emoji) : '')
        }
        textParts.push(clubText);

        if (club.sportTypes && club.sportTypes.length) {
            textParts.push($i18n.t('label.sport_type') + ': ' + getSportTypesSeoText(club));
        }

        if (club.followers_count) {
            textParts.push($i18n.t('label.fans') + ': ' + club.followers_count);
        }

        if (club.accepted_members_count) {
            textParts.push($i18n.t('label.members') + ': ' + club.accepted_members_count);
        }

        if (club.departments_count) {
            textParts.push($i18n.t('label.departments') + ': ' + club.departments_count);
        }

        return textParts.join(' ➤ ');
    }

    function getUserSeoDescription(user: User) {
        const {$i18n} = useNuxtApp();
        let textParts = [];

        let athleteText = user.presentation_type_name;
        if (user.country_code && user.coarse_address) {
            const emoji = getCountryEmoji(user.country_code);
            athleteText += ' ' + $i18n.t('conjunction.from') + ' ' + user.coarse_address + (emoji !== '' ? (' ' + emoji) : '')
        }
        textParts.push(athleteText);

        if (user.age) {
            textParts.push($i18n.t('label.age') + ': ' + user.age);
        }

        if (user.sportTypes && user.sportTypes.length) {
            textParts.push($i18n.t('label.sport_type') + ': ' + getSportTypesSeoText(user));
        }

        if (user.followers_count) {
            textParts.push($i18n.t('label.fans') + ': ' + user.followers_count);
        }

        return textParts.join(' ➤ ');
    }

    function getSportTypesSeoText(model: MainModelPreview, sportTypesLimit = 3) {
        const {$i18n} = useNuxtApp();

        if (!model.sportTypes || !model.sportTypes.length) {
            return '';
        }

        let sportTypes = [];
        for (let i = 0; i < Math.min(sportTypesLimit, model.sportTypes.length); i++) {
            let emoji = getSportTypeEmoji(model.sportTypes[i]);
            sportTypes.push(model.sportTypes[i].title + (emoji !== '' ? (' ' + emoji) : ''))
        }
        let sportTypesText = sportTypes.join(', ');
        let diff = model.sportTypes.length - sportTypesLimit
        if (diff > 0) {
            sportTypesText += ` ${$i18n.t('conjunction.and')} ${$i18n.t('param_label.more', diff, {count: diff})}`
        }

        return sportTypesText;
    }

    return {
        getEventSchemaId,
        getClubOrganizationSchema,
        getClubSeoDescription,
        getUserSeoDescription,
    }
}